import { css } from "@emotion/core"

export default css`
  display: flex;
  .tile {
    background-color: #585858;
    color: #fff;
    display: flex;
    flex-direction: column;
    flex: 1;
    .image {
      width: 100%;
      position: relative;
      overflow: hidden;
      .teardrop {
        z-index: 1;
        opacity: 1;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
      img {
        transition: all 3000ms;
        width: 100%;
        height: 100%;
      }

      &:hover {
        .teardrop {
          opacity: 0;
        }
        img {
          transition: all 3000ms;
          transform: rotate(3deg) scale(1.2, 1.2);
        }
      }
    }

    .description {
      padding: 1rem 2rem;
    }

    &:nth-child(even) {
      background-color: #c38e9e;
      flex-direction: column-reverse;
    }
  }

  @media (max-width: 720px) {
    flex-direction: column;

    .tile {
      flex-direction: column !important;
    }
  }
`
