import React from "react"
import css from "./location.css"

import Separator from "./separator"
import Map from "../components/map"
import { pinStart } from "../icons"

export default function Location() {
  const POIs = [
    {
      title: "Castillo de Moutas",
      icon: pinStart,
      lat: 43.489871764077236,
      lng: -6.113591194152833,
      info: "http://www.palaciodemoutas.es/",
      link: "https://goo.gl/maps/zWV9JJbhEwRC8gim6",
      img:
        "https://lh5.googleusercontent.com/p/AF1QipMumX_Ik_pS6zHmDkHzdRvknmCbWyPiMFIw0aNa=w426-h240-k-no",
    },
  ]

  return (
    <>
      <section css={css}>
        <div className={"tile"}>
          <div className={"image"}>
            <img src="../comedor.jpg" alt="Castillo de Moutas"></img>
            <a href=""></a>
          </div>
          <div className={"description"}>
            <h2>Comida</h2>
            <h4>Palacio de Moutas</h4>
            <Separator />
            <p>
              El cocktail, la comida y lo que surja, tendrá lugar en el Palacio
              de Moutas. Está situado justo al lado de la Iglesia para que
              ninguno se pierda en el camino. Un lugar increíble para hacer una
              celebración a juego.
            </p>
          </div>
        </div>
        <div className={"tile"}>
          <div className={"image"}>
            <img src="../castillo.jpg" alt="Castillo de Moutas"></img>
            <div className={"teardrop"}>
              <a href=""></a>
            </div>
          </div>
          <div className={"description"}>
            <h2>Ceremonia</h2>
            <h4>Iglesia de Santa Maria la Mayor a las 12:00</h4>
            <Separator />
            <p>
              La ceremonia se celebrará en la Iglesia de Santa María la Mayor
              (Colegiata de Pravia) a las 12:00. Situada en el corazón de
              Pravia, zona de fácil acceso.
            </p>
          </div>
        </div>
      </section>
      <section>
        <Map
          center={{
            lat: 43.489669386807996,
            lng: -6.112368106842042,
          }}
          zoom={17}
          POIs={POIs}
          selected={0}
        />
      </section>
    </>
  )
}
