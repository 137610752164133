import React from "react"

import useImages from "../hooks/useImages"
import useViewportSize, { SIZES } from "../hooks/useViewportSize"

import Layout from "../components/layout"
import Location from "../components/location"
import Playlist from "../components/playlist"
import Section from "../components/section"
import Separator from "../components/separator"

export default function Boda({ data }) {
  const { hero } = useImages()
  const viewportSize = useViewportSize()
  const heroContent = <></>

  return (
    <Layout
      heroImg={hero.childImageSharp}
      heroContent={heroContent}
      heroHeight={viewportSize === SIZES.XS ? "100px" : "250px"}
    >
      <Section
        style={{
          margin: "5rem 0",
          flexDirection: "column",
          textAlign: "center",
        }}
      >
        <h2>Agenda</h2>
        <Separator />
        <h3>25 de septiembre de 2021</h3>
      </Section>
      <Location></Location>
      <Playlist />
    </Layout>
  )
}
